import joinClasses from "helpers/joinClasses";
import "./inputs.css";

export function Radio({
  className = "",
  onClick = () => {},
  onChange = () => {},
  label = "",
  ...props
}) {
  return (
    <label className="block custom-radio-container font-montserrat-regular text-[20px] leading-[25px]">
      <input
        className={joinClasses("custom-radio", className)}
        type="radio"
        onChange={onChange}
        onClick={onClick}
        {...props}
      />
      {label}
    </label>
  );
}
