import { Card, PrimaryButton, Title } from "components/atoms";

export default function Welcome({ onNext = () => { } }) {


  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <div className="flex justify-between w-full w-full">
      <div
        className="w-full bg-no-repeat bg-center "
        style={{
          backgroundImage: `url("/assets/questions/1-2.svg")`,
        }}
      />
      <Card className="mt-16 z-10 flex flex-col justify-around">
        {/* TITLE BLOCK */}
        <div className="relative w-fit mx-auto">

          <Title className="text-center z-10 border-b border-black border-dotted uppercase text-transparent text-stroke text-stroke-black-500">Quiz</Title>

        </div>

        <h2 className="font-montserrat-light text-center text-[22px] text-fourth uppercase mt-8 mb-5">
          Être reconnu travailleur handicapé
        </h2>

        <p className="text-black text-center font-montserrat-regular text-[17px] leading-[24px]">
        Pour cette Semaine Européenne de l'Emploi des Personnes Handicapées (SEEPH) nous vous invitons à explorer les enjeux et les notions clés de la Reconnaissance de la Qualité de Travailleur Handicapé (RQTH)
        </p>

        <p className="text-black text-center font-montserrat-regular text-[17px] leading-[24px] mt-5">
        À vous de jouer 🚀
        </p>
        <div className="flex justify-center mt-10">
          <PrimaryButton onClick={handleClick}>Commencer</PrimaryButton>
        </div>
      </Card>
      <div
        className="w-full bg-no-repeat bg-center"
        style={{
          backgroundImage: `url("/assets/questions/1-1.svg")`,
        }}
      />
    </div>
  );
}