import { Header } from "components/atoms";
import { Footer } from "components/atoms/footer";
import joinClasses from "helpers/joinClasses";
import React from "react";

export function Template({
  children,
  backgroundColor = "bg-primary",
  dark = false,
}) {
  return (
    <div
      className={joinClasses(
        "min-h-screen max-w-screen overflow-hidden transition-all duration-300 ease-in-out relative",
        backgroundColor
      )}
    >
      <Header />
      <main>{children}</main>
      <Footer dark={dark} />
    </div>
  );
}
