import joinClasses from "helpers/joinClasses";
import Button from ".";

export function SecondaryButton({ className, children, onClick, ...props }) {
  return (
    <Button
      className={joinClasses(
        "bg-primary text-white !font-montserrat-regular uppercase text-[12px] select-none",
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}
