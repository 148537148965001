const questions = [
  {
    id: 1,
    name: "entry.400182990",
    type: "radio",
    title:
      "Qu'est-ce que la RQTH ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Un dispositif d'accompagnement pour les personnes handicapées.",
        value: "Un dispositif d'accompagnement pour les personnes handicapées.",
      },
      {
        label:
          "Une allocation financière accordée aux personnes handicapées.",
        value:
          "Une allocation financière accordée aux personnes handicapées.",
      },
      {
        label:
          "Une reconnaissance administrative du statut de travailleur handicapé.",
        value:
          "Une reconnaissance administrative du statut de travailleur handicapé.",
      },
    ],
    rightAnswers: [{
      answer: "Une reconnaissance administrative du statut de travailleur handicapé.",
    }],
    description: "La RQTH est un statut administratif. Il ne s'agit pas non plus d'une aide financière ou d'un accompagnement en tant que tel. Cependant, la RQTH permet d'accéder à des financements d'aménagement du poste de travail et à un accompagnement spécifique à l'emploi."
  },
  {
    id: 2,
    name: "entry.1042348623",
    type: "radio",
    title: "Que veut dire l'acronyme « RQTH » ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Recherche de Qualification pour Travailleurs Handicapés.",
        value: "Recherche de Qualification pour Travailleurs Handicapés.",
      },
      {
        label: "Reconnaissance de Qualité de Travailleur Handicapé.",
        value: "Reconnaissance de Qualité de Travailleur Handicapé.",
      },
      {
        label:
          "Rémunération pour Quotient de Travailleur Handicapé.",
        value:
          "Rémunération pour Quotient de Travailleur Handicapé.",
      },
    ],
    rightAnswers: [{
      answer: "Reconnaissance de Qualité de Travailleur Handicapé.",
    }],
    description: "La Reconnaissance de Qualité de travailleur Handicapé. Cette reconnaissance n'atteste pas de la qualification, des compétences, ou de la rémunération des travailleurs handicapés."
  },
  {
    id: 3,
    name: "entry.757304031",
    type: "radio",
    title:
      "Qui peut bénéficier de la RQTH ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Uniquement les personnes ayant un handicap physique.",
        value: "Uniquement les personnes ayant un handicap physique.",
      },
      {
        label: "Uniquement les personnes qui ont besoin d'un aménagement de poste.",
        value: "Uniquement les personnes qui ont besoin d'un aménagement de poste.",
      },
      {
        label: "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
        value: "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
      },
    ],
    rightAnswers: [{
      answer: "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
    }],
    description: "L'aménagement n'est nécessaire que dans 15 % des cas où une personne a une RQTH. Car la RQTH sert surtout à justifier qu'une situation de santé peut ou impacte directement le quotidien de travail, et peut jouer par exemple sur l'assiduité."
  },
  {
    id: 4,
    name: "entry.1937960583",
    type: "checkbox",
    title:
      "Quels sont les avantages de la RQTH pour les salariés ? ",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Aides ou prestations pour aménager la situation de travail et compenser le handicap (par exemple, le financement de prothèses auditives, l'achat d'un siège ergonomique...).",
        value: "Aides ou prestations pour aménager la situation de travail et compenser le handicap (par exemple, le financement de prothèses auditives, l'achat d'un siège ergonomique...).",
      },
      {
        label: "Possibilité d'avoir recours au CAP EMPLOI pour aider à la recherche de solutions (recherche d'emploi, formation, aménagements de poste).",
        value: "Possibilité d'avoir recours au CAP EMPLOI pour aider à la recherche de solutions (recherche d'emploi, formation, aménagements de poste).",
      },
      {
        label: "Bénéficier d'un suivi médical renforcé par le médecin du travail.",
        value: "Bénéficier d'un suivi médical renforcé par le médecin du travail.",
      },
      {
        label: "Aides pour sa réorientation et sa reconversion professionnelle quand l'ancien métier n'est plus possible (financement et appuis pour des bilans, des formations, des aides à la création d'entreprise...).",
        value: "Aides pour sa réorientation et sa reconversion professionnelle quand l'ancien métier n'est plus possible (financement et appuis pour des bilans, des formations, des aides à la création d'entreprise...).",
      },
    ],
    rightAnswers: [{
      answer: "Aides ou prestations pour aménager la situation de travail et compenser le handicap (par exemple, le financement de prothèses auditives, l'achat d'un siège ergonomique...).",
    },
    {
      answer: "Possibilité d'avoir recours au CAP EMPLOI pour aider à la recherche de solutions (recherche d'emploi, formation, aménagements de poste).",
    },
    {
      answer: "Bénéficier d'un suivi médical renforcé par le médecin du travail.",
    },
    {
      answer: "Aides pour sa réorientation et sa reconversion professionnelle quand l'ancien métier n'est plus possible (financement et appuis pour des bilans, des formations, des aides à la création d'entreprise...).",
    }],
    description: "Les quatre ! La RQTH présente de nombreux avantages pour le salarié, pour lui faciliter le quotidien et compenser autant que possible l'impact de sa situation de santé."
  },
  {
    id: 5,
    name: "entry.144365948",
    type: "checkbox",
    title: "Quels sont les avantages de la RQTH pour l'employeur ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Une diversité des compétences et des perspectives au sein de l'entreprise.",
        value: "Une diversité des compétences et des perspectives au sein de l'entreprise.",
      },
      {
        label: "Une manière d'être un employeur plus inclusif.",
        value: "Une manière d'être un employeur plus inclusif.",
      },
      {
        label: "Des aides financières pour financer les aménagements de poste.",
        value: "Des aides financières pour financer les aménagements de poste.",
      },
    ],
    rightAnswers: [{
      answer: "Une diversité des compétences et des perspectives au sein de l'entreprise.",
    },
    {
      answer: "Une manière d'être un employeur plus inclusif.",
    },
    {
      answer: "Des aides financières pour financer les aménagements de poste.",
    }],
    description: "L'employeur a lui aussi des avantages à accueillir dans ses équipes des personnes avec une RQTH, car cela lui permet de financer les aménagements de poste lorsque cela est nécessaire, car ces derniers peuvent être très coûteux. Cela permet aussi de diversifier les compétences et surtout les points de vue. Une personne à l'aise pour parler de sa RQTH pourra aussi mettre en confiance les collègues qui en ont besoin mais n'osent pas en parler. Cela permet de libérer la parole."
  },
  {
    id: 6,
    name: "entry.1109527574",
    type: "radio",
    title: "Comment obtenir une RQTH ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "En faisant une demande à la Maison Départementale des Personnes Handicapées (MDPH).",
        value: "En faisant une demande à la Maison Départementale des Personnes Handicapées (MDPH).",
      },
      {
        label: "En faisant la demande auprès de la sécurité sociale.",
        value: "En faisant la demande auprès de la sécurité sociale.",
      },
      {
        label: "En faisant la demande à sa mutuelle.",
        value: "En faisant la demande à sa mutuelle.",
      },
    ],
    rightAnswers: [{
      answer: "En faisant une demande à la Maison Départementale des Personnes Handicapées (MDPH).",
    }],
    description: "Seule la MDPH peut délivrer la Reconnaissance de Travailleur Handicapé. Même si pour cela, la participation du médecin traitant et de l'employeur peut être nécessaire pour l'obtenir."
  },
  {
    id: 7,
    name: "entry.1730232735",
    type: "radio",
    title:
      "La RQTH est le seul moyen de faire reconnaître sa situation de handicap au travail ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [{
      answer: "Faux",
    }],
    description: "Faux ! Il existe d'autres justificatifs en tant que travailleur handicapé comme l'incapacité supérieure à 10 %, l'invalidité, ou encore la carte mobilité inclusion. Il existe de nombreux justificatifs ! Mais la RQTH reste l'un des justificatifs les plus communs."
  },
  {
    id: 8,
    name: "entry.1656040742",
    type: "radio",
    title:
      "En transmettant ma RQTH, mon employeur sera informé de la nature de ma pathologie.",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [{
      answer: "Faux",
    }],
    description: "Faux, la nature de la pathologie n'apparaît pas sur la notification de la RQTH, et le salarié n'a aucune obligation de la dévoiler, sauf s'il le souhaite."
  },
  {
    id: 9,
    name: "entry.59470841",
    type: "radio",
    title:
      "Quel est l'objectif de la RQTH ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Reconnaître les compétences professionnelles des travailleurs handicapés.",
        value: "Reconnaître les compétences professionnelles des travailleurs handicapés.",
      },
      {
        label: "Faciliter l'accès à l'emploi des travailleurs handicapés.",
        value: "Faciliter l'accès à l'emploi des travailleurs handicapés.",
      },
      {
        label: "Avoir une compensation de revenu.",
        value: "Avoir une compensation de revenu.",
      },
    ],
    rightAnswers: [{
      answer: "Faciliter l'accès à l'emploi des travailleurs handicapés.",
    }],
    description: "La RQTH sert à faciliter l'accès à l'emploi des travailleurs handicapés puisque de nombreuses entreprises, choisissent, à compétences égales, d'intégrer une personne avec une RQTH. Par contre, la RQTH n'atteste pas des compétences professionnelles et n'apporte aucune compensation financière."
  },
  {
    id: 10,
    name: "entry.1005424498",
    type: "radio",
    title: "Un salarié ayant une RQTH risque de se voir refuser un prêt bancaire ou d'augmenter sa cotisation de mutuelle ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [{
      answer: "Faux",
    }],
    description: "La RQTH et les éléments du dossier sont confidentiels. Seul le salarié et la MDPH en ont connaissance. L'évaluation du handicap, pour la RQTH, ne vaut que pour le travail, pas pour la vie privée. Dans le cadre d'un prêt bancaire, seul le questionnaire de santé est à rendre, sans qu'on y demande si on est titulaire d'une RQTH."
  },
  {
    id: 11,
    name: "entry.781860223",
    type: "checkbox",
    title:
      "Un salarié qui souhaite savoir s'il est éligible à la RQTH et/ou qui souhaite se faire accompagner pour monter le dossier RQTH, à qui peut-il en parler ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "La mission Handicap.",
        value: "La mission Handicap.",
      },
      {
        label: "Le référent Handicap.",
        value: "Le référent Handicap.",
      },
      {
        label: "Le service RH.",
        value: "Le service RH.",
      },
      {
        label: "Son manager.",
        value: "Son manager.",
      },
      {
        label: "Le service de santé au travail.",
        value: "Le service de santé au travail.",
      },
    ],
    rightAnswers: [{
      answer: "La mission Handicap.",
    },
    {
      answer: "Le référent Handicap.",
    },
    {
      answer: "Le service RH.",
    },
    {
      answer: "Son manager.",
    },
    {
      answer: "Le service de santé au travail.",
    }],
    description: "Le salarié peut solliciter l'ensemble de ces personnes qui ont été sensibilisées sur le sujet. Il peut le faire à tout moment de l'année et en toute confidentialité. L'essentiel est de se sentir en confiance avec son interlocuteur. Ce dernier saura l'accompagner ou l'ori enter au mieux vers les bons interlocuteurs."
  },
  {
    id: 12,
    name: "entry.2119469881",
    type: "radio",
    title: "Lorsqu'un salarié fait une RQTH, l'employeur est automatiquement informé, ainsi que toutes les administrations publiques",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [{
      answer: "Faux",
    }],
    description: "Faux, l'employeur sera informé de la RQTH uniquement si le salarié lui transmet sa décision. Les administrations publiques ne sont jamais au courant de la RQTH, qui ne concerne que le volet professionnel."
  },
  {
    id: 13,
    name: "entry.1629248619",
    type: "radio",
    title:
      "Lorsqu'une maladie est provisoire, on ne peut pas se faire reconnaître une RQTH.",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [{
      answer: "Faux",
    }],
    description: "Faux ! La RQTH n'est pas figée dans le temps, et sa durée varie d'une personne à une autre en fonction de la situation. On peut l'obtenir à partir du moment où la pathologie est prévisible à au moins une année."
  },
  {
    id: 14,
    name: "entry.1332747395",
    type: "radio",
    title:
      "En déclarant sa RQTH à son employeur, quelles peuvent être les conséquences :",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Perte de son emploi",
        value: "Perte de son emploi",
      },
      {
        label: "Une information auprès des collègues",
        value: "Une information auprès des collègues",
      },
      {
        label: "Prévention des risques pour conserver son emploi",
        value: "Prévention des risques pour conserver son emploi",
      },
      {
        label: "Changement de poste vers une voie de garage",
        value: "Changement de poste vers une voie de garage",
      },
      {
        label: "Il n'est pas nécessaire de compléter cette réponse",
        value: "Il n'est pas nécessaire de compléter cette réponse",
      },
    ],
    rightAnswers: [{
      answer: "Prévention des risques pour conserver son emploi",
    }],
    description: "La RQTH permet de prévenir les risques liés à sa situation de santé afin de conserver son emploi dans les meilleures conditions, le plus longtemps possible."
  },
  {
    id: 15,
    name: "entry.1640098632",
    type: "radio",
    title:
      "Pour transmettre sa RQTH, il faut absolument que la situation de santé donne lieu à un aménagement de poste :",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [{
      answer: "Faux",
    }],
    description: "Faux, l'aménagement de poste n'est nécessaire que dans 15 % des situations de handicap au travail. La RQTH peut être transmise même lorsqu'aucune action n'est nécessaire, et plutôt dans le cadre préventif."
  },
  {
    id: 16,
    name: "entry.1184826046",
    type: "radio",
    title:
      "Cas pratique : un salarié a une RQTH, il a besoin de prendre une pause plus longue, que fait-il ?",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Il continue comme avant, en prenant une pause aussi longue que celle des autres",
        value: "Il continue comme avant, en prenant une pause aussi longue que celle des autres",
      },
      {
        label: "Il en parle à son manager ou son service RH",
        value: "Il en parle à son manager ou son service RH",
      },
      {
        label: "Il prend une pause plus longue sans en parler en amont, car la RQTH lui octroie le droit de s'adapter à son besoin",
        value: "Il prend une pause plus longue sans en parler en amont, car la RQTH lui octroie le droit de s'adapter à son besoin",
      },
    ],
    rightAnswers: [{
      answer: "Il en parle à son manager ou son service RH",
    }],
    description: "Tout aménagement de poste, qu'il soit matériel, organisationnel ou horaire, doit être validé et discuté avec le manager, le service RH et le service de santé et sécurité au travail. Le salarié, même s'il a transmis sa RQTH, ne peut pas décider seul de son aménagement de poste."
  },
  {
    id: 17,
    name: "entry.77325295",
    type: "radio",
    title:
      "Un salarié dyslexique peut-il obtenir la RQTH ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
    rightAnswers: [{
      answer: "Oui",
    }],
    description: "Le salarié peut obtenir la RQTH, car la dyslexie est un trouble cognitif handicapant au quotidien pour un salarié qui doit lire et écrire dans le cadre de ses fonctions. Cela va notamment lui demander des efforts supplémentaires pour ses actions en comparaison à une personne non-dyslexique."
  },
  {
    id: 18,
    name: "entry.95861815",
    type: "radio",
    title:
      "Il faut obligatoirement que le handicap se voie pour se faire reconnaître RQTH.",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [{
      answer: "Faux",
    }],
    description: "C'est faux, car 80 % des handicaps sont invisibles, et les difficultés sont pourtant bien présentes ! Ce n'est pas parce que le handicap ne se voit pas que les difficultés en sont moins réelles."
  },
  {
    id: 19,
    name: "entry.34135706",
    type: "radio",
    title:
      "Existe-t-il un référent handicap chez VISEO ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
    rightAnswers: [{
      answer: "Oui",
    }],
    description: "Oui ! Il s’agit de Alicia BECQUET qui a pris sa casquette de référente handicap en 2023, elle se tient disponible pour répondre à toutes les questions des salariés sur le sujet du handicap. Et accompagner ceux qui en ont besoin à remplir leur demande de RQTH."
  },
];

export default questions;
