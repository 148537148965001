import joinClasses from "helpers/joinClasses";
import Button from ".";

export function PrimaryButton({ className, children, onClick, ...props }) {
  return (
    <Button
      className={joinClasses("bg-primary text-white uppercase text-[14px] select-none", className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}
